<template>
    <dialog
        id="schedule-followup-dialog"
        ref="scheduleFollowupDialog"
        class="max-w-full md:max-w-fit w-full md:w-auto m-0 mt-auto md:m-auto focus-visible:outline-0 bg-white px-6 py-10 rounded-b-none md:rounded-b-xl rounded-xl text-valence-grey-800 relative">
        <div class="flex flex-col items-center gap-6 text-center max-w-prose">
            <div class="text-2xl font-semibold tracking-tighter">{{ title }}</div>
            <p v-if="submitError" class="max-w-xs text-sm font-medium text-red-400">Sorry, I&apos;m having trouble scheduling our follow-up. Please try again later.</p>
            <div class="flex flex-col gap-4">
                <ScheduleFollowUp
                    :date="date"
                    :recurring="recurring"
                    :is-collapsed="isCollapsed"
                    :coach-followup-events="coachFollowupEvents"
                    @date-update="onDateUpdate"
                    @recurring-update="onRecurringUpdate" />
                <button
                    :disabled="loading"
                    type="button"
                    autofocus
                    class="rounded-full text-white bg-[#555BA2] hover:bg-[#555BA2]/80 font-bold text-sm py-3 px-16 leading-6 self-center"
                    :class="{ 'bg-[#555BA2]/80': loading }"
                    @click="schedule">
                    <div v-if="loading" class="h-5 w-5 inline-block mx-auto">
                        <LoadingSpinner />
                    </div>
                    <template v-else> Schedule </template>
                </button>
                <button type="button" class="font-semibold text-[#555BA2] self-center" @click="dismiss">{{ dismissText }}</button>
            </div>
        </div>
    </dialog>
</template>

<script setup>
import { nextWorkday, roundToNearest15 } from "/js/dateUtils.js";
import { router } from "@inertiajs/vue3";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import ScheduleFollowUp from "~vue/ScheduleFollowUp.vue";
import { logError } from "~vue/utils/logUtils";
import { DateTime } from "luxon";
import { inject, ref } from "vue";

const { coachFollowupEvents, initialDate, eventDescription, customSchedule } = defineProps({
    coachFollowupEvents: {
        // comes from coach_followup_events
        type: Array,
        default: () => [],
        required: false,
    },
    title: { type: String, default: "Nadia Check-in" },
    dismissText: { type: String, default: "Dismiss" },
    initialDate: { type: Date, default: undefined },
    eventDescription: { type: String, default: undefined },
    customSchedule: { type: Function, default: undefined },
});

const { $sendEvent } = inject("globalProperties");
const userDetails = inject("user_details");
const DEFAULT_EVENT_DESCRIPTION = "Let's discuss what's coming up for you this week.";

const scheduleFollowupDialog = ref(null);
const date = ref(initialDate ?? nextWorkday(roundToNearest15(DateTime.now().plus({ days: 3 }))).toJSDate());
const loading = ref(false);
const submitError = ref(null);
const event = ref(null);
const recurring = ref(userDetails.prefers_weekly_check_in);
const isCollapsed = ref(false); // used to set initial collapsed state on ScheduleFollowup

const schedule = async () => {
    try {
        submitError.value = null;
        loading.value = true;
        if (customSchedule) {
            await customSchedule({ recurring: recurring.value, selectedDate: date.value });
        } else {
            await $sendEvent("schedule_follow_up", {
                event_description: eventDescription ?? event.value?.invite_description ?? DEFAULT_EVENT_DESCRIPTION,
                event_at_confirmed: DateTime.fromJSDate(date.value).toISO(),
                recurring: recurring.value,
                type: "followup",
                id: event.value ? event.value.id : 0,
            });
            router.reload({ only: ["coach_followup_events"] });
        }
        dismiss();
    } catch (e) {
        submitError.value = e;
        logError();
    } finally {
        loading.value = false;
    }
};

const onDateUpdate = (newDate) => {
    date.value = newDate;
};
const onRecurringUpdate = (toggled) => {
    recurring.value = toggled;
};

const open = ({ eventData, showCalendar = false } = {}) => {
    if (eventData) {
        event.value = eventData;
        date.value = DateTime.fromISO(eventData.event_at_confirmed).toJSDate();
        recurring.value = eventData.recurring || false;
    }
    if (showCalendar) {
        isCollapsed.value = false;
    }

    scheduleFollowupDialog.value.showModal();
};

const dismiss = () => {
    submitError.value = null;
    scheduleFollowupDialog.value.close();
    const path = window.location.pathname;
    window.history.replaceState({}, "", path);
};

defineExpose({ open, dismiss });
</script>

<style scoped>
dialog#schedule-followup-dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
